<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon> -->
            Ware House
            <v-spacer></v-spacer>
            <add-button permission="subject-read" @action="form.dialog = true"
              >New WareHouse
            </add-button>
          </v-card-title>

          <v-card flat>
            <v-card outlined>
              <v-card-title class="title">
                <v-flex xs4>
                  <v-text-field
                    outlined
                    dense
                    hint="Atleast 3 characters"
                    color="primary"
                    class="pa-0"
                    label="Search By Name"
                    v-model="searchAH"
                    @input="searchCategory"
                    append-icon="search"
                  />
                </v-flex>
              </v-card-title>
            </v-card>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td style="font-weight: bold" class="text-xs-left">
                  {{ item.name }}
                </td>
                <td class="text-xs-left">
                  {{
                    findBranch(item.branch_id)
                      ? findBranch(item.branch_id).name
                      : ''
                  }}
                </td>
                <td class="text-xs-left">
                  {{ item.parent_name ? item.parent_name : '-' }}
                </td>
                <td class="text-xs-left">
                  <v-chip
                    v-if="item.is_primary === 1"
                    color="green"
                    text-color="white"
                    >Yes</v-chip
                  >
                  <v-chip v-else color="red" text-color="white">No</v-chip>
                </td>

                <td class="text-right">
                  <span v-if="item.company_id">
                    <edit-button
                      permission="subject-read"
                      @agree="form.edit(item), editData(item)"
                    />
                    <delete-button
                      permission="section-create"
                      @agree="form.delete(item.id)"
                    />
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="form.dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="title primary white--text"
          >WareHouse Information</v-card-title
        >
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs8>
                  <v-text-field
                    v-model="form.name"
                    name="name"
                    label="Name"
                    :error-messages="form.errors.get('name')"
                    :height="25"
                    autocomplete="off"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-checkbox
                    color="blue"
                    v-model="form.is_primary"
                    label="Primary"
                  >
                  </v-checkbox>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    class="pa-1"
                    label="Select Warehouse Branch"
                    v-model="form.branch_id"
                    name="branch_id"
                    :items="branches"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="form.parent_id"
                    :items="items"
                    :search-input.sync="search"
                    hide-no-data
                    item-text="Name"
                    item-value="id"
                    label="Parent Warehouse"
                    :disabled="form.is_primary"
                    placeholder="Start typing to Search"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="warning"
            outlined
            text
            @click="(form.dialog = false), form.reset()"
            >Cancel</v-btn
          >
          <v-btn color="success" outlined text @click="save">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: '',
        description: '',
        is_primary: false,
        parent_id: '',
        branch_id: '',
      },
      '/api/account/inventory/warehouse'
    ),
    searchAH: null,
    search: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    entries: [],
    accountHeads: [],
    branches: [],
    branchData: [],
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '30px' },
      { text: 'Name', value: 'name', align: 'left', width: '30%' },
      {
        text: 'Branch',
        value: 'branch_name',
        align: 'left',
        width: '20%',
        sortable: false,
      },
      {
        text: 'Parent',
        value: 'parent_name',
        align: 'left',
        width: '20%',
        sortable: false,
      },
      {
        text: 'Primary',
        value: 'is_primary',
        align: 'left',
        width: '10%',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        align: 'right',
        width: '20%',
      },
    ],
    footerProps: {
      'items-per-page-options': [10, 25, 50, 100, -1],
    },
  }),

  computed: {
    ...mapState(['batch']),
    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    search(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get('/api/account/inventory/warehouse?parent=1&search=' + val)
          .then(({ data }) => {
            // this.entries = data.data
            this.entries = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
  },

  mounted() {
    this.getBranch();
  },
  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&search=' +
        this.searchAH
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.accountHeads = data.data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      });
    },
    editData(data) {
      this.entries = [];
      if (this.form.is_primary) this.form.is_primary = true;
      else this.form.is_primary = false;
      if (data.parent_name && data.parent_id)
        this.entries.push({ name: data.parent_name, id: data.parent_id });
      else this.entries = [];
    },

    save() {
      if (this.form.is_primary) this.form.is_primary = 1;
      else this.form.is_primary = 0;
      // this.form.parent_id = this.form.parent_id.id;
      this.form
        .store()
        .then(() => {
          this.form.dialog = false;

          this.$events.fire('notification', {
            message: res.data.message,
            status: 'success',
          });
        })
        .catch((err) => {
          this.form.dialog = false;
          this.form.name = '';
          this.form.description = '';
          this.form.is_primary = false;
          this.form.parent_id = '';
          this.$events.fire('notification', {
            message: err.response.message,
            status: 'error',
          });
        });
      this.form.is_primary = false;
    },
    searchCategory: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.get();
        // if (v.length > 2 || !v.length) this.get()
      }, 500);
    },
    getBranch() {
      this.$rest.get('/api/account/inventory/branch').then((res) => {
        this.branchData = res.data.data;
        this.branches = res.data.data.map((res) => {
          return {
            value: res.id,
            text: res.name,
          };
        });
      });
    },
    findBranch(branch_id) {
      return this.branchData.find((res) => res.id === branch_id);
    },
  },
};
</script>
<style lang="scss"></style>
